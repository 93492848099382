#home {
    .mv {
        margin-top: 105px;
        margin-bottom: 105px;

        .ttl {
            text-align: center;
            font-size: 3.5rem;
            margin-bottom: 20px;
            font-weight: bold;
            @include sp{
                font-size: 2rem;
                margin-bottom: 0;
            }
        }

        .logo {
            text-align: center;
            margin-bottom: 25px;
            svg{
                @include sp{
                    width: 80%;
                }
            }
        }

        .subttl {
            text-align: center;
            font-size: 1.5rem;
            font-weight: bold;
            @include sp{
                font-size: 1rem;
            }
        }
    }

    .study {
        width: 100%;
        background-color: #F4FCFF;
        padding: 40px 0 60px;
        margin-bottom: 120px;

        .ttl {
            text-align: center;
            font-size: 2.8rem;
            margin-bottom: 40px;
            @include sp{
                font-size: 1.8rem;
                margin-bottom: 20px;
            }
        }

        &__flex {
            display: flex;
            justify-content: space-between;
            @include sp{
                display: block;
            }
            img {
                width: 45%;
                @include sp{
                    width: 100%;
                    margin-bottom: 20px;
                }
            }

            p {
                width: 45%;
                font-size: 16px;
                @include sp{
                    width: 100%;
                }
            }
        }
    }

    .learn {
        margin-bottom: 120px;

        .ttl {
            text-align: center;
            font-size: 2.8rem;
            @include sp{
                font-size: 1.8rem;
                margin-bottom: 20px;
            }
        }

        &__flex {
            // max-width: 900px;
            width: 100%;
            margin: 50px auto 50px;
            display: flex;
            justify-content: space-between;
            gap: 0 20px;
            grid-template-columns: repeat(2, 1fr);
            @include sp{
                display: block;
            }
            img{
                width: 45%;
                @include sp{
                    width: 100%;
                }
            }
            p {
                width: 45%;
                font-size: 16px;
                @include sp{
                    width: 100%;
                }
                svg{
                    display: block;
                    margin: auto;
                }
            }
        }
    }

    .course {
        .ttl {
            text-align: center;
            font-size: 2.8rem;
            margin-bottom: 60px;
            @include sp{
                font-size: 1.8rem;
                margin-bottom: 20px;
            }
        }

        &__flex {
            display: flex;
            justify-content: space-between;
            list-style: none;
            flex-wrap: wrap;
            padding: 0;
            @include sp{
                display: block;
            }
            li {
                padding: 25px 25px;
                margin-bottom: 60px;
                width: 30%;
                box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1);
                @include sp{
                    width: 100%;
                }
                a {
                    display: block;
                    text-decoration: none;
                }

                &:hover {
                    // box-shadow: none;
                }
            }
        }

        &__category {
            text-align: center;
            font-weight: bold;
            font-size: .8rem;
        }

        &__ttl {
            text-align: center;
            font-weight: bold;
            font-size: 1.1rem;
            color: #006F98;
            margin-bottom: 10px;
        }

        &__logo {
            margin-bottom: 10px;
        }

        &__txt {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: .8rem;
        }

        &__count {
            text-align: center;
            font-weight: bold;
            font-size: .8rem;
            margin-bottom: 20px;
            span {
                font-size: 1.3rem;
            }
        }
        &__blue{
            background-color: #F4FCFF;
            padding: 25px 0;
            h4{
                font-weight: bold;
                font-size: .9rem;
                color: #006F98;
                position: relative;
                margin-bottom: 10px;
                text-align: center;
                &::before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 28px;
                    transform: translateY(-50%);
                    width: 40px;
                    height: 2px;
                    background-color: #006F98;
                }
                &::after{
                    position: absolute;
                    content: "";
                    top: 50%;
                    right: 28px;
                    transform: translateY(-50%);
                    width: 40px;
                    height: 2px;
                    background-color: #006F98;
                }
            }
            p{
                padding: 0 25px;
            }
        }
    }

    .resolution {
        margin-top: 120px;
        margin-bottom: 120px;
        background-color: #F4FCFF;
        padding: 40px 0;
        .ttl {
            text-align: center;
            font-size: 2.8rem;
            margin-bottom: 60px;
            @include sp{
                font-size: 1.8rem;
                margin-bottom: 20px;
            }
        }

        &__flex {
            display: flex;
            justify-content: space-between;
            margin-bottom: 80px;
            @include sp{
                display: block;
            }
            img {
                width: 45%;
                @include sp{
                    width: 100%;
                    margin-bottom: 20px;
                }
            }

            p {
                width: 45%;
                font-size: 16px;
                @include sp{
                    width: 100%;
                }
            }
        }
        .btn__flex{
            @include sp{
                display: block;
            }
            a{
                @include sp{
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .news{
        margin-top: 120px;
        margin-bottom: 120px;
        .ttl {
            text-align: center;
            font-size: 2.8rem;
            margin-bottom: 60px;
            @include sp{
                font-size: 1.8rem;
                margin-bottom: 20px;
            }
        }
        ul{
            max-width: 900px;
            margin: auto;
            list-style-type: none;
            margin-bottom: 30px;
            li{
                border-bottom: 1px solid #B4B4B4;
                font-weight: bold;
                padding: 25px 50px;
                @include sp{
                    padding: 10px;
                }
                a{
                    text-decoration: none;
                    font-size: 1.1rem;
                    .date{
                        margin-right: 30px;
                        @include sp{
                            display: block;
                        }
                    }
                }
            }
        }
        .bl_btn_sm{
            max-width: 365px;
            margin-top: 40px;
            padding: 10px;
            @include sp{
                font-size: 1rem;
            }
        }
    }

    .cta{
        margin-top: 120px;
        margin-bottom: 120px;
        background-color: #F8F8F8;
        padding: 40px 0;
        .ttl {
            text-align: center;
            font-size: 2.8rem;
            margin-bottom: 10px;
            @include sp{
                font-size: 1.8rem;
                margin-bottom: 20px;
            }
        }
        p{
            text-align: center;
            margin-bottom: 40px;
            font-size: 1.3rem;
        }
        a{
            display: block;
            margin: auto;
            width: 100%;
            max-width: 320px;
            padding: 15px 0 15px 30px;
            text-align: center;
            font-size: 1.1rem;
            font-weight: bold;
            text-decoration: none;
            margin-bottom: 30px;
            background-color: #fff;
            border: 1px solid #000000;
            border-radius: 10px;
            &.x-regiter{
                border: 3px solid #000;
                position: relative;
                &::before{
                    position: absolute;
                    content: "";
                    top: 47%;
                    left: 28px;
                    width: 25px;
                    height: 25px;
                    background-image: url(/assets/frontend/images/register/icon_x.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    transform: translateY(-50%);
                }
            }
            &.go-regiter{
                border: 3px solid #ea4335;
                position: relative;
                &::before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 28px;
                    width: 29px;
                    height: 29px;
                    background-image: url(/assets/frontend/images/register/icon_google.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    transform: translateY(-50%);
                }
            }
            &.git-regiter{
                border: 3px solid #0e0c0d;
                position: relative;
                &::before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 28px;
                    width: 26px;
                    height: 26px;
                    background-image: url(/assets/frontend/images/register/icon_github.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    transform: translateY(-50%);
                }
            }
            &.fb-regiter{
                border: 3px solid #1878F2;
                position: relative;
                &::before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 28px;
                    width: 29px;
                    height: 29px;
                    background-image: url(/assets/frontend/images/register/icon_facebook.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.swiper--wrapper {
    /* wrapperのサイズを調整 */
    width: 100%;
    height: 300px;
}

.swiper-slide {
    /* スライドのサイズを調整、中身のテキスト配置調整、背景色 */
    color: #ffffff;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 300px;
    text-align: center;
}

.swiper-slide:nth-child(3n + 1) {
    background-color: #de4439;
}

.swiper-slide:nth-child(3n + 2) {
    background-color: #fcd500;
}

.swiper-slide:nth-child(3n + 3) {
    background-color: #53c638;
}
