.c-input {
    display: block;
    align-items: center;

    label {
        // max-width: 220px;
        // width: 100%;
        font-size: 14px;
        font-weight: bold;
    }

    &__ttl {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 8px;
    }
    .c-input__required {
        margin-left: 10px;
        font-size: 11px;
        background-color: $alert-color;
        color: #fff;
        padding: 2px 10px;
        border-radius: 5px;
    }

    input {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        font-size: 14px;

        &:focus {
            outline: none;
            border-color: #3cd5ff;
        }

        &[type="submit"] {
            background-color: $maincolor;
            color: #fff;
            width: 100%;
            max-width: 173px;
            text-align: center;
            display: block;
            margin: auto;
            font-weight: bold;
            text-decoration: none;
            font-size: 1.2rem;
            border-radius: 10px;
            border: 1px solid $maincolor;
        }

        &[type="checkbox"] {
            width: auto;
            margin-right: 10px;
            margin: 0;
            padding: 0;
            background: none;
            border: none;
            border-radius: 0;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            cursor: pointer;
            padding-left: 30px;
            vertical-align: super;
            position: relative;

            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
            }

            &::before {
                background-color: #fff;
                border-radius: 0%;
                border: 1px solid #666464;
                width: 20px;
                height: 20px;
                transform: translateY(-50%);
                top: 50%;
                left: 5px;
            }

            &::after {
                border-bottom: 3px solid $linkcolor;
                border-left: 3px solid $linkcolor;
                opacity: 0;
                height: 6px;
                width: 11px;
                transform: rotate(-45deg);
                top: -7px;
                left: 10px;
            }

            &:checked::after {
                opacity: 1;
            }
        }

        &[type="radio"] {
            width: auto;
            margin-right: 10px;
            margin: 0;
            padding: 0;
            background: none;
            border: none;
            border-radius: 0;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 20px;
            height: 20px;
            border-radius: 100px;
            position: relative;
            vertical-align: sub;
            margin-right: 8px;

            &::before,
            &::after {
                content: "";
                display: block;
                border-radius: 50%;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
            }

            &::before {
                background-color: #fff;
                border: 1px solid #c2c1c1;
                height: 20px;
                width: 20px;
                left: 0px;
            }

            &::after {
                background-color: $linkcolor;
                opacity: 0;
                height: 14px;
                width: 14px;
                left: 4px;
            }

            &:checked::after {
                opacity: 1;
            }
        }
    }

    &__checkbox_label {
        margin-right: 20px;
    }
    &__checkbox__blk{
        display: flex;
        flex-wrap: wrap;
        &__item{
            width: 33%;
            margin-bottom: 5px;
        }
    }
    &__radio__blk{
        display: flex;
        flex-wrap: wrap;
        .c-input__checkbox__blk__item{
            width: 33%;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
        }
    }

    &__radio_label {
        margin-right: 20px;
    }

    select {
        width: 100%;
        font-size: 14px;
        border-radius: 10px;
        padding: 10px;
        border: 1px solid #ccc;
    }

    textarea {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        font-size: 14px;

        &:focus {
            outline: none;
            border-color: #3cd5ff;
        }
    }
}

// バリデーション
.validation__msg{
    color: $alert-color;
    font-size: 12px;
    margin-top: 5px;
    background-color: #ffd9dd;
    padding: 5px 10px;
    width: 100%;
}
