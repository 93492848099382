#footer {
    .sns_link {
        width: 100%;
        max-width: 210px;
        margin: 30px auto 30px;

        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;

            li {
                padding: 0 2rem;
                list-style: none;
            }
        }
    }

    .footer__line {
        border-top: 1px solid #d0d0d0;
    }

    .footer__link {
        padding: 50px 30px 20px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        max-width: 1200px;
        margin:auto;
        @include sp{
            display: block;
        }

        .logo {
            width: 100%;
            max-width: 153px;
            @include sp{
                margin: 0 auto 40px;
            }
        }

        .footer__navi {
            width: 100%;
            max-width: 600px;
            display: flex;
            justify-content: space-between;
            @include sp{
                display: block;
            }
            ul {
                li {
                    list-style-type: none;
                    line-height: 2.4;

                    a {
                        font-size: .9rem;
                        text-decoration: none;
                    }
                }
                &:nth-child(1){
                    @include sp{
                        display: none;
                    }
                }
            }
        }

        .footer_login_btn {
            @include sp{
                margin-top: 30px;
            }
            p {
                font-size: 0.8rem;
                margin-bottom: 20px;
                @include sp{
                    text-align: center;
                    font-size: 1rem;
                }
            }

            a {
                background-color: $maincolor;
                color: #fff;
                width: 100%;
                max-width: 173px;
                height: 43px;
                text-align: center;
                line-height: 43px;
                display: block;
                font-weight: bold;
                text-decoration: none;
                font-size: 1.2rem;
                border-radius: 10px;
                border: 1px solid $maincolor;
                @include sp{
                    display: block;
                    margin: auto;
                }
                &:hover{
                    background-color: #fff;
                    color: $maincolor;
                }
            }
        }
    }

    .copy {
        text-align: center;
        font-size: .7rem;
        padding: 30px 0 30px;
    }
}
