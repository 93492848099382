#help {
    width: 100%;
    margin: 0 auto 120px;

    .help__flex {
        display: flex;
        justify-content: space-between;

        &__sidebar {
            width: 28%;
            background-color: #F4FCFF;
            padding: 30px 25px;
            height: 100%;

            &__block {
                margin-bottom: 30px;
                border-bottom: 1px solid #006F98;
                padding-bottom: 20px;

                h3 {
                    font-size: 1rem;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                ul {
                    padding: 0 15px 0px 40px;
                    list-style-type: none;

                    li {
                        font-size: .8rem;
                        line-height: 2.2;
                        position: relative;

                        &::before {
                            position: absolute;
                            content: "";
                            top: 46%;
                            left: -21px;
                            width: 13px;
                            height: 13px;
                            transform: translateY(-50%);
                            background-color: $strongblue;
                            border-radius: 50%;
                        }

                        a {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        &__content {
            width: 65%;

            dl {
                margin-bottom: 50px;

                dt {
                    font-weight: bold;
                    font-size: 1.2rem;
                    padding-bottom: 5px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid #D5D5D5;
                }
            }
        }
    }
}
