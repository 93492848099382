#contact{
    .contact_form{
        width: 100%;
        max-width: 750px;
        margin: 0 auto 120px;
        &__lead{
            margin: 30px 0;
            font-size: 16px;
        }
        .send_btn{
            width: 100%;
            max-width:300px;
            padding: 20px 0;
            text-align: center;
            display: block;
            margin: auto;
            color: #fff;
            background-color: $maincolor;
            border: 1px solid $maincolor;
            font-weight: bold;
            font-size: 1.2rem;
            border-radius: 20px;
            cursor: pointer;
            letter-spacing: 2px;
            &:hover{
                background-color: #fff;
                color: $maincolor;
                border:1px solid $maincolor;
            }
        }
    }

    .contact_complete{
        margin-bottom: 120px;
        &__blk{
            width: 100%;
            text-align: center;
            h3{
                font-size: 22px;
            }
            h4{
                font-size: 18px;
            }
            p{
                font-size: 16px;
                margin-bottom: 40px;
            }
        }
    }
}
