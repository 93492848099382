.questionnaire{
    &__blk{
        margin: 50px auto 120px;
        max-width: 600px;
        &__ttl{
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 50px;
        }
        &__lists{
            margin: 0 auto 40px;
            padding-bottom: 40px;
            border-bottom: 1px solid #eeeeee;
        }
    }
}

.questionnaire__blk__lists__form_txt{
    margin: 7px 0;
    font-size: 14px;
}
