@mixin pc {
	@media screen and (min-width: 768px) {
		@content;
	}
}

@mixin tab {
	@media only screen and (min-width: 767px) and (max-width: 1000px) {
		@content;
	}
}

@mixin sp {
	@media screen and (max-width: 767px) {
		@content;
	}
}

@mixin prefixes($property, $value) {
	@each $prefix in -webkit-, -moz-, -ms-, -o-, "" {
		#{$prefix}#{$property}: $value;
	}
}

$maincolor: #03B9FC;
$linkcolor: #0877C9;
$strongblue: #006F98;
$alert-color: #ff293e;

:root {
    overflow-x: hidden;
    --border-radius: 12px;
    --base-color: #1B224C;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
img {
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
    vertical-align: bottom;
}
body{
    overflow-x: hidden;
    word-wrap: break-word;
    position: relative;
    color: var(--base-color);
    background-color: #fff;
    font-size: 62.5%;
    letter-spacing: .1rem;
    line-height: 1.8;
    font-family: -apple-system,
    BlinkMacSystemFont,
    "Helvetica Neue",
    HelveticaNeue,
    "Segoe UI",
    "游ゴシック体",
    YuGothic,
    "Yu Gothic M",
    "游ゴシック Medium",
    "Yu Gothic Medium",
    "ヒラギノ角ゴ ProN W3",
    "Hiragino Kaku Gothic ProN W3",
    HiraKakuProN-W3,
    "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN",
    "ヒラギノ角ゴ Pro",
    "Hiragino Kaku Gothic Pro",
    "メイリオ",
    Meiryo,
    Osaka,
    "ＭＳ Ｐゴシック",
    "MS PGothic",
    sans-serif;
}
a{
    color: var(--base-color);
    transition : .5s;
    // &:hover{
    //     color: $maincolor;
    //     transition : .5s;
    // }
}
p{
    font-size: .9rem;
}
ul,ol{
    padding: 0 0px 0 0px;
}
ul li,
ol li{
    font-size:.9rem;
}
dl{
    font-size: .9rem;
    dt{
        font-weight: bold;
    }
}

.inner {
    max-width: 1200px;
    margin: auto;

    @include sp {
        width: 90%;
    }
}

.vpc {
    @include pc {
        display: block;
    }
    @include sp {
        display: none;
    }
}

.vsp {
    @include pc {
        display: none;
    }
    @include sp {
        display: block;
    }
}

// ボタンコンポーネント
.bl_btn_lg{
    max-width: 300px;
    width: 100%;
    color: #fff;
    background-color: $maincolor;
    border:1px solid $maincolor;
    padding: 19px 15px;
    text-align: center;
    display: block;
    margin: auto;
    text-decoration: none;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    &:hover{
        background-color: #fff;
        color: $maincolor;
        border: 1px solid $maincolor;
    }
}

.bl_btn_md{
    max-width: 240px;
    width: 100%;
    color: #fff;
    background-color: $maincolor;
    border:1px solid $maincolor;
    padding: 9px 15px;
    text-align: center;
    display: block;
    margin: auto;
    text-decoration: none;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    &:hover{
        background-color: #fff;
        color: $maincolor;
        border: 1px solid $maincolor;
    }
}

.bl_btn_sm{
    max-width: 175px;
    width: 100%;
    color: #fff;
    background-color: $maincolor;
    border:1px solid $maincolor;
    padding: 3px 15px;
    text-align: center;
    display: block;
    margin: auto;
    text-decoration: none;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: bold;
    &:hover{
        border: 1px solid $maincolor;
        background-color: #fff;
        color: $maincolor;
    }
}

.bl_btn_xs{
    max-width: 125px;
    width: 100%;
    color: #fff;
    background-color: $maincolor;
    border:1px solid $maincolor;
    padding: 5px 15px;
    text-align: center;
    display: block;
    margin: auto;
    text-decoration: none;
    border-radius: 10px;
    font-size: .8rem;
    font-weight: bold;
    &:hover{
        background-color: #fff;
        color: $maincolor;
    }
}

.btn_gray{
    background-color: #999;
    border:1px solid #999;
}

.btn__flex{
    width: 100%;
    max-width: 550px;
    margin: auto;
    display: flex;
    column-gap: 2%;
    a{
        width: 48%;
    }
}

.p-0 {
    padding: 0 !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-10 {
    padding-top: 10px !important
}

.pt-20 {
    padding-top: 20px !important
}

.pt-30 {
    padding-top: 30px !important
}

.pt-40 {
    padding-top: 40px !important
}

.pt-50 {
    padding-top: 50px !important
}

.pt-140 {
    padding-top: 140px !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-10 {
    padding-bottom: 10px !important
}

.pb-20 {
    padding-bottom: 20px !important
}

.pb-30 {
    padding-bottom: 30px !important
}

.pb-40 {
    padding-bottom: 40px !important
}

.pb-50 {
    padding-bottom: 50px !important
}

.pb-140 {
    padding-bottom: 140px !important
}

.m-0 {
    margin: 0 !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-10 {
    margin-top: 10px !important
}

.mt-20 {
    margin-top: 20px !important
}

.mt-30 {
    margin-top: 30px !important
}

.mt-40 {
    margin-top: 40px !important
}

.mt-50 {
    margin-top: 50px !important
}

.mt-60 {
    margin-top: 60px !important
}

.mt-70 {
    margin-top: 70px !important
}

.mt-80 {
    margin-top: 80px !important
}

.mt-90 {
    margin-top: 90px !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-10 {
    margin-bottom: 10px !important
}

.mb-20 {
    margin-bottom: 20px !important
}

.mb-30 {
    margin-bottom: 30px !important
}

.mb-40 {
    margin-bottom: 40px !important
}

.mb-50 {
    margin-bottom: 50px !important
}

.mb-60 {
    margin-bottom: 60px !important
}

.mb-70 {
    margin-bottom: 70px !important
}

.mb-80 {
    margin-bottom: 80px !important
}
.mb-90 {
    margin-bottom: 90px !important
}
.mb-120 {
    margin-bottom: 120px !important
}

// 下層ページのページタイトル
.under_intro{
    margin: 50px 0;
    &__ttl{
        letter-spacing: .3rem;
        text-align: center;
        font-size: 2.2rem;
        @include sp{
            font-size: 1.8rem;
        }
    }
}

// @keyframes hide {
//     to {
//         opacity: 0;
//         visibility: hidden;
//     }
// }

// アラート
.alert{
    // animation: hide 3s linear forwards;
    // position: fixed;
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-size: .9rem;
    // top: 10%;
    // left: 50%;
    // transform: translateX(-50%);
    z-index: 500;
    // box-shadow: 0 10px 35px 0 rgba(0, 0, 0, .2);
}
.alert-success{
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}
.alert-danger{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}

@keyframes fadeout {
    from {opacity: 1;}
    to   {opacity: 0;}
}

#flash-message {
    animation-name: fadeout;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 2s;
}

// フォーム
.label_input{
    font-size: 1rem;
    font-weight: bold;
}
.label_check{
    font-size: 1rem;
}
.input{
    display: block;
    width: 100%;
    border:1px solid #ddd;
    border-radius: 10px;
    padding: 15px 15px;
    font-size: .9rem;
    color: #212529;
    background-color: #fff;
    appearance: none;
}
.required{
    color: #E50000;
    margin-left: 10px;
}


// ページャー
nav[role="navigation"]{
    display: flex;
    justify-content: center;
    margin-bottom: 120px;
    svg{
        width: 40px;
        height: 40px;
        padding-bottom: 5px;
    }
    p.text-sm.text-gray-700.leading-5{
        display: none;
    }
    .flex.justify-between.flex-1{
        display: none;
    }
    .relative.z-0.inline-flex.shadow-sm.rounded-md{
        display: inline-flex;
    }
    a{
        font-size: 1.2rem;
        text-decoration: none;
        margin: 0 14px;
        font-weight: bold;
    }
    span{
        font-size: 1.2rem;
        color: $maincolor;
        margin: 0 6.5px;
        font-weight: bold;
    }
}
