#operator{
    width: 100%;
    max-width: 750px;
    margin: 0 auto 120px;
    dl{
        display:flex;
        padding: 35px 0 20px;
        border-bottom: 1px solid #e9e9e9;
        dt{
            width: 100%;
            max-width: 200px;
        }
        dd{
            width: 100%;
            max-width: 700px;
        }
    }
}
