#register{
    width: 100%;
    .register__snslink{
        margin-bottom: 190px;
        .ttl{
            text-align: center;
            font-size: 1.3rem;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .subttl{
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 30px;
        }
        a{
            width: 300px;
            display: block;
            position: relative;
            text-align: center;
            border: 1px solid #d0d0d0;
            padding: 10px 0;
            font-size: 1rem;
            font-weight: bold;
            text-decoration: none;
            margin: 0 auto 25px;
            border-radius: 10px;
            &.register__x{
                border: 3px solid #000;
                padding-left: 20px;
                &::after{
                    position: absolute;
                    content: "";
                    top:50%;
                    left: 65px;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                    background:url(../images/register/icon_x.svg) no-repeat;
                    background-size: 100%;
                }
            }
            &.register__gobtn{
                border: 3px solid #EC4233;
                padding-left: 20px;
                &::after{
                    position: absolute;
                    content: "";
                    top:50%;
                    left: 65px;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                    background:url(../images/register/icon_google.svg) no-repeat;
                    background-size: 100%;
                }
            }
            &.register__fbbtn{
                border: 3px solid #1878F2;
                padding-left: 20px;
                &::after{
                    position: absolute;
                    content: "";
                    top:50%;
                    left: 60px;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                    background:url(../images/register/icon_facebook.svg) no-repeat;
                    background-size: 100%;
                }
            }
            &.register__git{
                border: 3px solid #000;
                padding-left: 20px;
                &::after{
                    position: absolute;
                    content: "";
                    top:50%;
                    left: 65px;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                    background:url(../images/register/icon_github.svg) no-repeat;
                    background-size: 100%;
                }
            }
        }
    }
}
