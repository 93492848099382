#navigation {
    position: fixed;
    z-index: 1000;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 40px -13px #bdbdbd;

    .header {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: 100%;
            max-width: 120px;
            padding-top: 12px;
            display: block;

            svg {
                width: 100%;
            }
        }

        .navi {
            @include sp {
                display: none;
            }

            ul {
                display: flex;
                justify-content: space-between;
                width: 100%;
                max-width: 690px;
                list-style-type: none;

                li {
                    padding: 1.2rem;

                    a {
                        text-decoration: none;
                        font-size: .9rem;
                        color: #1B224C;

                        &:hover {
                            color: $maincolor;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .login_register {
            display: flex;
            justify-content: space-between;
            gap: 0 10px;
            // max-width: 250px;
            // width: 100%;

            .login_link {
                // width: 100%;
                // max-width: 100px;

                a {
                    text-decoration: none;
                    font-size: .9rem;
                    color: #1B224C;
                    padding: 1.2rem 0;
                    display: block;

                    &:hover {
                        color: $maincolor;
                        font-weight: bold;
                    }
                }
            }

            .register_link {
                // width: 100%;
                // max-width: 150px;
                margin-top: 15px;

                a {
                    max-width: 100%;
                    font-size: .9rem;
                }
            }
            .accout_btn {
                max-width: 100%;
                font-size: 1rem;
                font-weight: bold;
                text-decoration: none;
                position: relative;
                cursor: pointer;
            }
            .dropdown-content {
                display: none;
                position: absolute;
                top: 55px;
                background-color: #f9f9f9;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                &.show {
                    display: block;
                }
                a {
                    color: black;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: block;
                    font-size: .9rem;
                    &:hover {
                        background-color: #f1f1f1;
                    }
                }
            }
        }
    }
}
