#mypage {
    width: 100%;
    .mypage__questionnaire{
        margin:0px auto 30px;
        border: 1px solid #9ce2fb;
        background: #d1f3ff;
        color: #000;
        padding: 15px;
        font-weight: bold;
        text-align: center;
        // box-shadow: 0px 1px 40px -13px #bdbdbd;
        .bl_btn_xs{
            max-width: 180px;
            margin-top: 10px;
        }
    }
    .mypage__flex {
        display: flex;
        justify-content: space-between;
        @include sp{
            display: block;
        }
        &__sidebar {
            width: 28%;
            height: 100%;
            @include sp{
                display: none;
            }

            &__block {
                margin-bottom: 30px;
                padding: 30px 25px 0px;
                background-color: #F4FCFF;

                h3 {
                    font-size: 1rem;
                    font-weight: bold;
                    margin-bottom: 25px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #006F98;
                }

                &__courselist {
                    padding: 0 15px 0px 15px;
                    list-style-type: none;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    li {
                        line-height: 2.2;
                        position: relative;
                        width: 47%;
                        text-align: center;
                        margin-bottom: 20px;
                        p{
                            font-size: .8rem;
                        }
                        .course__logo{
                            border-radius: 30px;
                        }
                        a {
                            text-decoration: none;
                        }
                    }
                }
            }
            &__link{
                &__list{
                    list-style: none;
                    li{
                        a{
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        &__content {
            width: 65%;
            @include sp{
                width: 100%;
            }
            .ttl {
                font-size: 2rem;
                @include sp{
                    text-align: center;
                }
            }

            .mypage__states {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 90px;
                align-items: center;
                @include sp{
                    display: block;
                }

                .profile__img {
                    width: 20%;
                    @include sp{
                        width: 100%;
                    }

                    img {
                        border-radius: 50%;
                        object-fit: cover;
                        width: 80px;
                        height: 80px;
                        display: block;
                        margin: auto;
                    }
                }

                .profile__situation {
                    width: 75%;
                    @include sp{
                        width: 100%;
                    }
                    .progress {
                        margin-top: 15px;

                        &__account {
                            font-weight: bold;
                            margin-left: 10px;
                            @include sp{
                                text-align: center;
                                margin-left: 0;
                                margin-bottom: 20px;
                            }
                        }

                        &__percent {
                            display: flex;
                            align-items: center;
                            column-gap: 5%;
                            justify-content: flex-start;
                            width: 90%;
                            margin: auto;

                            p {
                                position: relative;
                                font-size: .9rem;
                                font-weight: bold;

                                span {
                                    font-size: 3rem;
                                    vertical-align: sub;
                                    margin-left: 10px;
                                }

                                &::before {
                                    position: absolute;
                                    content: "";
                                    bottom: 0;
                                    width: 100%;
                                    height: 1px;
                                    background: #707070;
                                }
                            }
                        }
                    }
                }
            }

            &__resent,
            &__history {
                margin-bottom: 100px;

                .mypage_subttl {
                    font-size: 1.3rem;
                    margin-bottom: 20px;
                    margin-top: 25px;

                    span {
                        display: block;
                        float: right;

                        a {
                            font-size: .9rem;
                            position: relative;
                            padding-right: 20px;
                            color: $linkcolor;

                            &:before {
                                position: absolute;
                                content: "";
                                top: 37%;
                                right: 0;
                                transform: translateY(-50%);
                                width: 6px;
                                height: 6px;
                                border: 1px solid;
                                border-color: $linkcolor $linkcolor transparent transparent;
                                transform: rotate(45deg);
                            }
                        }
                    }
                }

                .course_history__flex {
                    display: flex;
                    justify-content: space-between;
                    list-style: none;
                    flex-wrap: wrap;
                    padding: 0;
                    @include sp{
                        display: block;
                    }

                    li {
                        padding: 25px 25px;
                        margin-bottom: 30px;
                        width: 100%;
                        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1);

                        .course_history__block {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            @include sp{
                                display: block;
                            }
                            .logo_img {
                                width: 20%;
                                @include sp{
                                    width: 100%;
                                }
                            }

                            .course__detail {
                                width: 75%;
                                display: flex;
                                justify-content: space-between;
                                @include sp{
                                    width: 100%;
                                    display: block;
                                }

                                &__ttl {
                                    width: 70%;
                                    @include sp{
                                        width: 100%;
                                    }

                                    .course__category {
                                        font-weight: bold;
                                        font-size: .8rem;
                                        @include sp{
                                            text-align: center;
                                        }
                                    }

                                    .course__ttl {
                                        font-weight: bold;
                                        font-size: 1.1rem;
                                        color: #006F98;
                                        margin-bottom: 10px;
                                        @include sp{
                                            text-align: center;
                                        }
                                    }

                                    .course__txt {
                                        margin-bottom: 10px;
                                        font-weight: bold;
                                        font-size: .8rem;
                                        @include sp{
                                            text-align: center;
                                        }
                                    }
                                }

                                &__post {
                                    .course__count {
                                        font-weight: bold;
                                        font-size: .8rem;
                                        margin-bottom: 20px;
                                        @include sp{
                                            text-align: center;
                                        }

                                        span {
                                            font-size: 1.3rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__history {
                .mypage_subttl {
                    font-size: 1.3rem;
                    margin-bottom: 20px;
                    display: block;

                    span {
                        display: block;
                        float: right;

                        a {
                            font-size: .9rem;
                            position: relative;
                            padding-right: 20px;
                            color: $linkcolor;

                            &:before {
                                position: absolute;
                                content: "";
                                top: 37%;
                                right: 0;
                                transform: translateY(-50%);
                                width: 6px;
                                height: 6px;
                                border: 1px solid;
                                border-color: $linkcolor $linkcolor transparent transparent;
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
            }

            .profile_tbl {
                width: 100%;
                margin-bottom: 150px;
                margin-top: 60px;
                &__profile_img{
                    display: block;
                    margin: auto;
                    img{
                        display: block;
                        margin: auto;
                        width: 80px;
                        height: 80px;
                    }
                }
                table {
                    margin-bottom: 30px;
                    @include sp{
                        width: 100%;
                    }

                    th {
                        width: 20%;
                        padding: 25px 15px;
                        font-size: 1rem;
                        font-weight: bold;
                        // border-bottom: 1px solid #eee;
                        @include sp{
                            display: block;
                            width: 100%;
                            text-align: left;
                            border-bottom:none;
                        }
                    }

                    td {
                        font-size: 1rem;
                        padding: 25px 15px;
                        // border-bottom: 1px solid #eee;
                        @include sp{
                            display: block;
                            width: 100%;
                            text-align: left;
                        }
                    }
                }

            }
        }
    }


    // 退会ページ
    .withdrawal {
        text-align: center;

        P {
            font-size: 1.2rem;
        }

        a {
            margin-top: 20px;
        }
    }
}
