.breadcrumb{
    width: 100%;
    margin: 75px 0 20px;
    ul{
        list-style-type: none;
        display: flex;
        padding: 0;
        li{
            color: #868E96;
            position: relative;
            margin-right: 60px;
            a{
                font-size: .9rem;
                text-decoration: none;
                color: $linkcolor;
            }
            &::before{
                position: absolute;
                content: "";
                top: 36%;
                left: -35px;
                transform: translateY(-50%);
                width: 6px;
                height: 6px;
                border: 1px solid;
                border-color: #565656 #565656 transparent transparent;
                transform: rotate(45deg);
            }
            &:nth-child(1){
                &::before{
                    display: none;
                }
            }
        }
    }
}
