.problem {
    margin-top: 65px;
    .inner{
        max-width: 100%;
        &.answer{
            max-width: 1200px;
            margin: auto;
            .problem__flex{
                justify-content: center;
                .problem__flex__content{
                    width: 80%;
                    overflow-y:auto;
                    box-shadow: none;
                    &__ttl{
                        text-align: center;
                    }
                }
            }
        }
    }
    &__flex {
        display: flex;
        justify-content: center;

        &__sidebar {
            width: 30%;
            height: 100%;
            margin-left: 1%;
            max-height: 95%;
            min-height: 40%;
            overflow-y: scroll;
            &::-webkit-scrollbar{
                width: 11px;
            }
            &::-webkit-scrollbar-track{
                background-color: #fcfcfc;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #c4edff;
                border-radius: 15px;
            }
            &__block {
                margin-bottom: 30px;
                padding: 30px 25px 0px;
                background-color: #F4FCFF;
                box-shadow: 0 1px 3px rgba(160,166,179,0.3);
                height: 100vh;
                overflow-y: scroll;
                &::-webkit-scrollbar{
                    width: 11px;
                }
                &::-webkit-scrollbar-track{
                    background-color: #fcfcfc;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #c4edff;
                    border-radius: 15px;
                }

                .problem__sidebar__ttl {
                    font-size: 1rem;
                    font-weight: bold;
                    margin-bottom: 25px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #006F98;
                }
                .problem__sidebar__description{
                    font-size: .9rem;
                    margin-bottom: 40px;
                }

                &__courselist {
                    padding: 0 15px 0px 15px;
                    list-style-type: none;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    li {
                        line-height: 2.2;
                        position: relative;
                        width: 47%;
                        text-align: center;
                        margin-bottom: 20px;
                        p{
                            font-size: .8rem;
                        }
                        .course__logo{
                            border-radius: 30px;
                        }
                        a {
                            text-decoration: none;
                        }
                    }
                }
            }
            &__link{
                &__list{
                    list-style: none;
                    li{
                        a{
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        &__content {
            width: 61%;
            margin: 0 1%;
            padding: 1%;
            background-color: #fff;
            box-shadow: 0 1px 3px rgba(160, 166, 179, 0.3);
            overflow-y: scroll;
            height: 100vh;
            &::-webkit-scrollbar{
                width: 10px;
            }
            &::-webkit-scrollbar-track{
                background-color: #e0e0e0;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #a7def5;
                border-radius: 15px;
            }
            h3{
                font-size: 1.8rem;
                margin-bottom: 10px;
                span{
                    font-size: 1rem;
                    margin-left: 20px;
                }
            }
            &__txt{
                margin-bottom: 40px;
            }
            &__editor {
                position: relative;
                display: block;
                height: 500px;
                margin-bottom: 120px;
                #editor {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    overflow: auto;
                    // height: 800px !important;
                }
                &.complete{
                    height: auto;
                }
            }

            // 回答のページ
            .answer__ttl{
                font-size: 1.4rem;
                font-weight: bold;
                margin: 30px 0 5px;
                text-align: center;
            }
            .answer__body{
                background-color:#f3f3f3;
                padding: 35px 40px;
                font-size: .9rem;
                margin: 50px 0 60px;
                position: relative;
                box-shadow: 0 1px 3px rgba(160, 166, 179, 0.3);
                &::before{
                    position: absolute;
                    content: "Q.";
                    font-size: 4rem;
                    color: #8d8d8d;
                    font-weight: bold;
                    top: 0px;
                    transform: translate(-50%, -50%);
                    left: 5%;
                    z-index: 0;
                }
                &__txt{
                    position: relative;
                    z-index: 1;
                }
            }

            .answer__blk{
                background-color: #fff7ff;
                box-shadow: 0 1px 3px rgba(160, 166, 179, 0.3);
                padding: 35px 40px;
                position: relative;
                &::before{
                    position: absolute;
                    content: "A.";
                    font-size: 4rem;
                    color: #ffbde7;
                    font-weight: bold;
                    top: 0px;
                    left: 5%;
                    transform: translate(-50%, -50%);
                    z-index: 0;
                }
                .answer__txt{
                    font-size: .9rem;
                    margin-bottom: 5px;
                }
                .input{
                    border: 1px solid #c8c8c8;
                    margin: 20px 0;
                }
            }
            .answer__submit{
                margin: 40px 0;
                &.btn_list{
                    display: flex;
                    .bl_btn_back_md{
                        max-width: 240px;
                        width: 100%;
                        color: #fff;
                        background-color: #414141;
                        border: 1px solid #414141;
                        padding: 9px 15px;
                        text-align: center;
                        display: initial;
                        margin: auto;
                        text-decoration: none;
                        border-radius: 10px;
                        font-size: 1.2rem;
                        font-weight: bold;
                        cursor: pointer;
                        &:hover{
                            background-color: #fff;
                            color: #414141;
                            border: 1px solid #414141;
                        }
                    }
                    .bl_btn_md{
                        display: initial;
                    }
                }
            }
            // 正解ページ
            .answer__ttl_complete{
                margin: 30px 0 20px;
                text-align: center;
                display: block;
            }
            // 不正解ページ
            .answer__btnlist{
                display: flex;
                max-width: 650px;
                width: 100%;
                margin: auto;
            }
        }
        &__document{
            width: 30%;
            background-color: #f7f7f7;
            box-shadow: 0 1px 3px rgba(160,166,179,0.3);
            padding: 7px;
            margin-right: 1%;
            height: 100vh;
            h4{
                font-size: 1.2rem;
            }
            #previewArea{
                width: 100%;
                min-height: 40%;
                max-height: 95%;
                border: 1px solid #e6e6e6;
                background-color: #fff;
            }
        }
    }
}
