.error{
    text-align: center;
    margin-bottom: 100px;
    &__ttl{
        font-size: 55px;
    }
    &__txt{
        font-size: 16px;
        margin-bottom: 50px;
    }
}
