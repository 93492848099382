#course {
    width: 100%;
    margin-bottom: 120px;
    .course {
        .ttl {
            text-align: center;
            font-size: 2.8rem;
            margin-bottom: 60px;
        }

        &__flex {
            display: flex;
            justify-content: left;
            list-style: none;
            flex-wrap: wrap;
            padding: 0;
            margin-bottom: 70px;
            gap: 0 5%;
            @include sp{
                display: block;
            }

            li {
                padding: 25px 25px;
                margin-bottom: 60px;
                width: 30%;
                box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1);
                @include sp{
                    width: 100%;
                }
                a {
                    display: block;
                    text-decoration: none;
                }

                // &:nth-child(3n+2) {
                //     margin: 0 3% 60px;
                // }
            }
        }

        &__category {
            text-align: center;
            font-weight: bold;
            font-size: .8rem;
        }

        &__ttl {
            text-align: center;
            font-weight: bold;
            font-size: 1.1rem;
            color: #006F98;
            margin-bottom: 10px;
        }

        &__ttlcategory {
            font-size: 1.6rem;
            color: $strongblue;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
            display: flex;
            align-items: center;
            &::before,
            &::after{
                content: "";
                height: 1px;
                flex-grow: 1;
                background-color: $strongblue;
            }
            &:before {
                margin-right: 1rem;
            }
            &:after {
                margin-left: 1rem;
            }
        }

        &__logo {
            margin-bottom: 10px;
            display: block;
            margin: 10px auto;
        }

        &__txt {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: .8rem;
            text-align: center;
        }

        &__count {
            text-align: center;
            font-weight: bold;
            font-size: .8rem;
            margin-bottom: 20px;

            span {
                font-size: 1.3rem;
            }
        }

        &__blue {
            background-color: #F4FCFF;
            padding: 25px 0;
            margin-bottom: 20px;

            h4 {
                font-weight: bold;
                font-size: .9rem;
                color: #006F98;
                position: relative;
                margin-bottom: 10px;
                text-align: center;

                &::before {
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 28px;
                    transform: translateY(-50%);
                    width: 40px;
                    height: 2px;
                    background-color: #006F98;
                    @include sp{
                        width: 20px;
                    }
                }

                &::after {
                    position: absolute;
                    content: "";
                    top: 50%;
                    right: 28px;
                    transform: translateY(-50%);
                    width: 40px;
                    height: 2px;
                    background-color: #006F98;
                    @include sp{
                        width: 20px;
                    }
                }
            }

            p {
                padding: 0 25px;
            }
        }

        &__list {
            width: 100%;
            max-width: 800px;
            margin: auto;

            ul {
                display: flex;
                    justify-content: space-between;
                    list-style: none;
                    flex-wrap: wrap;
                    padding: 0;

                li {
                    padding: 25px 25px;
                    margin-bottom: 30px;
                    width: 100%;
                    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);

                    .course__list__block {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                    .logo_img {
                        width: 25%;
                    }
                    .course__list__detail {
                        width: 70%;
                        display: flex;
                        justify-content: space-between;

                        &__ttl {
                            width: 68%;

                            .course__list__category {
                                font-weight: bold;
                                font-size: .8rem;
                            }

                            .course__list__ttl {
                                font-weight: bold;
                                font-size: 1.1rem;
                                color: #006F98;
                                margin-bottom: 10px;
                            }

                            .course__list__txt {
                                margin-bottom: 10px;
                                font-weight: bold;
                                font-size: .8rem;
                            }
                        }

                        &__post {
                            .course__list__count {
                                font-weight: bold;
                                font-size: .8rem;
                                margin-bottom: 20px;

                                span {
                                    font-size: 1.3rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
