#information{
    ul{
        list-style-type: none;
        margin: auto;
        margin-bottom: 150px;
        max-width: 1000px;
        width: 100%;
        li{
            border-bottom: 1px solid #D5D5D5;
            padding: 15px 30px;
            @include sp{
                padding: 15px 0;
            }
            a{
                display: flex;
                text-decoration: none;
                font-size: 1rem;
                @include sp{
                    display: block;
                }
                .date__label{

                    .date{
                        max-width: 120px;
                        width: 100%;
                        margin-right: 40px;
                        font-weight: bold;
                        @include sp{
                            display: block;
                        }
                    }
                    .label{
                        padding: 3px 10px;
                        background-color: $maincolor;
                    }
                }
                .ttl{
                    font-weight: bold;
                    font-size: 15px;
                }
            }
        }
    }
}


#news_detail{
    margin-bottom: 230px;
    .news_detail__flex{
        display: flex;
        justify-content: space-between;
        @include sp{
            display: block;
        }
    }
    .news_detail__content{
        width: 100%;
        max-width: 765px;
        .date_cetegory{
            margin-bottom: 5px;
            font-size: 14px;
            span{
                margin-left: 30px;
                background-color: #1B224C;
                color: #fff;
                padding: 3px 10px;
            }
        }
        .ttl{
            font-size: 1.5rem;
            margin-bottom: 10px;
        }
        .news_detail__body{
            font-size: 14px;
            min-height: 200px;
        }
        .news_detail_nextprev{
            display: flex;
            margin-top: 100px;
            align-items: center;
            .prev{
                position: relative;
                font-size: 1rem;
                text-decoration: none;
                margin: 11px 15px;
                width: 33.3%;
                @include sp{
                    font-size: .8rem;
                    margin: 10px 5px;
                }
                &::before{
                    position: absolute;
                    content: "";
                    top: 8px;
                    left: -25px;
                    width: 8px;
                    height: 11px;
                    background: url(../images/information/icon_arrow_left.svg) no-repeat;
                    @include sp{
                        top: 7px;
                        left: -15px;
                    }
                }
            }
            .next{
                position: relative;
                font-size: 1rem;
                text-decoration: none;
                margin: 11px 15px;
                width: 33.3%;
                text-align: right;
                @include sp{
                    font-size: .8rem;
                    margin: 10px 5px;
                }
                &::before{
                    position: absolute;
                    content: "";
                    top: 8px;
                    right: -25px;
                    width: 8px;
                    height: 11px;
                    background: url(../images/information/icon_arrow_right.svg) no-repeat;
                    @include sp{
                        top: 7px;
                        right: -15px;
                    }
                }
            }
            .btn{
                font-size: 1rem;
                border: 1px solid #03B9FC;
                background-color: #03B9FC;
                color: #fff;
                padding: 10px 40px;
                border-radius: 10px;
                text-decoration: none;
                text-align: center;
                width: 33.3%;
                @include sp{
                    padding: 5px 20px;
                    font-size: .8rem;
                }
                &:hover{
                    background-color: #fff;
                    color: $maincolor;
                }
            }
        }
    }
    .news_detail__sidebar{
        width: 100%;
        max-width: 300px;
        margin-bottom: 30px;
        padding: 30px 25px 0px;
        background-color: #F4FCFF;
        @include sp{
            width: 100%;
            max-width: 100%;
            margin-top: 120px;
        }
        &__ttl{
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 25px;
            padding-bottom: 10px;
            border-bottom: 1px solid #006F98;
        }
        ul{
            padding: 0 15px 0px 15px;
            list-style-type: none;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li{
                line-height: 2.2;
                position: relative;
                width: 47%;
                text-align: center;
                margin-bottom: 20px;
                a{
                    text-decoration: none;
                }
                .course__logo{
                    border-radius: 30px;
                }
            }
        }
    }
}
