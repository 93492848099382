#about{
    width: 100%;
    margin-bottom: 150px;
    .inner{
        margin: 90px auto;
        @include sp{
            margin: 30px auto;
        }
    }
    .about__flex{
        display: flex;
        justify-content: space-between;
        margin-bottom: 180px;
        @include sp{
            display: block;
            margin-bottom: 50px;
        }
        .img{
            width: 47%;
            @include sp{
                width: 100%;
                padding: 20px 0px;
            }
        }
        .txt{
            width: 47%;
            padding: 0 20px;
            @include sp{
                width: 100%;
                padding: 20px 0px;
            }
            h2{
                font-size: 1.8rem;
                font-weight: bold;
                color: $strongblue;
                position: relative;
                counter-increment: mycounter;
                @include sp{
                    font-size: 1.6rem;
                }
                &::before{
                    content: "POINT";
                    width: 100%;
                    font-size: 100px;
                    position: absolute;
                    top: -85px;
                    left: 0;
                    color: #eaf9ff;
                    z-index: -1;
                    font-family: "Roboto", sans-serif;
                }
            }
            p{
                font-size: 16px;
            }
        }
    }
    .about__center{
        width: 70%;
        margin: 0 auto 130px;
        @include sp{
            width: 100%;
        }
        .img{
            width: 100%;
            display: block;
            margin: 0 auto;
        }
        .txt{
            width: 100%;
            text-align: center;
            margin-bottom: 80px;
            h2{
                font-size: 1.8rem;
                font-weight: bold;
                color: $strongblue;
            }
            p{
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
}
