@charset "UTF-8";
:root {
  overflow-x: hidden;
  --border-radius: 12px;
  --base-color: #1B224C;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}

body {
  overflow-x: hidden;
  word-wrap: break-word;
  position: relative;
  color: var(--base-color);
  background-color: #fff;
  font-size: 62.5%;
  letter-spacing: 0.1rem;
  line-height: 1.8;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", HelveticaNeue, "Segoe UI", "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

a {
  color: var(--base-color);
  transition: 0.5s;
}

p {
  font-size: 0.9rem;
}

ul, ol {
  padding: 0 0px 0 0px;
}

ul li,
ol li {
  font-size: 0.9rem;
}

dl {
  font-size: 0.9rem;
}
dl dt {
  font-weight: bold;
}

.inner {
  max-width: 1200px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .inner {
    width: 90%;
  }
}

@media screen and (min-width: 768px) {
  .vpc {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .vpc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .vsp {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .vsp {
    display: block;
  }
}

.bl_btn_lg {
  max-width: 300px;
  width: 100%;
  color: #fff;
  background-color: #03B9FC;
  border: 1px solid #03B9FC;
  padding: 19px 15px;
  text-align: center;
  display: block;
  margin: auto;
  text-decoration: none;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.bl_btn_lg:hover {
  background-color: #fff;
  color: #03B9FC;
  border: 1px solid #03B9FC;
}

.bl_btn_md {
  max-width: 240px;
  width: 100%;
  color: #fff;
  background-color: #03B9FC;
  border: 1px solid #03B9FC;
  padding: 9px 15px;
  text-align: center;
  display: block;
  margin: auto;
  text-decoration: none;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.bl_btn_md:hover {
  background-color: #fff;
  color: #03B9FC;
  border: 1px solid #03B9FC;
}

.bl_btn_sm {
  max-width: 175px;
  width: 100%;
  color: #fff;
  background-color: #03B9FC;
  border: 1px solid #03B9FC;
  padding: 3px 15px;
  text-align: center;
  display: block;
  margin: auto;
  text-decoration: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: bold;
}
.bl_btn_sm:hover {
  border: 1px solid #03B9FC;
  background-color: #fff;
  color: #03B9FC;
}

.bl_btn_xs {
  max-width: 125px;
  width: 100%;
  color: #fff;
  background-color: #03B9FC;
  border: 1px solid #03B9FC;
  padding: 5px 15px;
  text-align: center;
  display: block;
  margin: auto;
  text-decoration: none;
  border-radius: 10px;
  font-size: 0.8rem;
  font-weight: bold;
}
.bl_btn_xs:hover {
  background-color: #fff;
  color: #03B9FC;
}

.btn_gray {
  background-color: #999;
  border: 1px solid #999;
}

.btn__flex {
  width: 100%;
  max-width: 550px;
  margin: auto;
  display: flex;
  column-gap: 2%;
}
.btn__flex a {
  width: 48%;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.under_intro {
  margin: 50px 0;
}
.under_intro__ttl {
  letter-spacing: 0.3rem;
  text-align: center;
  font-size: 2.2rem;
}
@media screen and (max-width: 767px) {
  .under_intro__ttl {
    font-size: 1.8rem;
  }
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  z-index: 500;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#flash-message {
  animation-name: fadeout;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

.label_input {
  font-size: 1rem;
  font-weight: bold;
}

.label_check {
  font-size: 1rem;
}

.input {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px 15px;
  font-size: 0.9rem;
  color: #212529;
  background-color: #fff;
  appearance: none;
}

.required {
  color: #E50000;
  margin-left: 10px;
}

nav[role=navigation] {
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
}
nav[role=navigation] svg {
  width: 40px;
  height: 40px;
  padding-bottom: 5px;
}
nav[role=navigation] p.text-sm.text-gray-700.leading-5 {
  display: none;
}
nav[role=navigation] .flex.justify-between.flex-1 {
  display: none;
}
nav[role=navigation] .relative.z-0.inline-flex.shadow-sm.rounded-md {
  display: inline-flex;
}
nav[role=navigation] a {
  font-size: 1.2rem;
  text-decoration: none;
  margin: 0 14px;
  font-weight: bold;
}
nav[role=navigation] span {
  font-size: 1.2rem;
  color: #03B9FC;
  margin: 0 6.5px;
  font-weight: bold;
}

#home .mv {
  margin-top: 105px;
  margin-bottom: 105px;
}
#home .mv .ttl {
  text-align: center;
  font-size: 3.5rem;
  margin-bottom: 20px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  #home .mv .ttl {
    font-size: 2rem;
    margin-bottom: 0;
  }
}
#home .mv .logo {
  text-align: center;
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  #home .mv .logo svg {
    width: 80%;
  }
}
#home .mv .subttl {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  #home .mv .subttl {
    font-size: 1rem;
  }
}
#home .study {
  width: 100%;
  background-color: #F4FCFF;
  padding: 40px 0 60px;
  margin-bottom: 120px;
}
#home .study .ttl {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  #home .study .ttl {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}
#home .study__flex {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  #home .study__flex {
    display: block;
  }
}
#home .study__flex img {
  width: 45%;
}
@media screen and (max-width: 767px) {
  #home .study__flex img {
    width: 100%;
    margin-bottom: 20px;
  }
}
#home .study__flex p {
  width: 45%;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  #home .study__flex p {
    width: 100%;
  }
}
#home .learn {
  margin-bottom: 120px;
}
#home .learn .ttl {
  text-align: center;
  font-size: 2.8rem;
}
@media screen and (max-width: 767px) {
  #home .learn .ttl {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}
#home .learn__flex {
  width: 100%;
  margin: 50px auto 50px;
  display: flex;
  justify-content: space-between;
  gap: 0 20px;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 767px) {
  #home .learn__flex {
    display: block;
  }
}
#home .learn__flex img {
  width: 45%;
}
@media screen and (max-width: 767px) {
  #home .learn__flex img {
    width: 100%;
  }
}
#home .learn__flex p {
  width: 45%;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  #home .learn__flex p {
    width: 100%;
  }
}
#home .learn__flex p svg {
  display: block;
  margin: auto;
}
#home .course .ttl {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 60px;
}
@media screen and (max-width: 767px) {
  #home .course .ttl {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}
#home .course__flex {
  display: flex;
  justify-content: space-between;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
}
@media screen and (max-width: 767px) {
  #home .course__flex {
    display: block;
  }
}
#home .course__flex li {
  padding: 25px 25px;
  margin-bottom: 60px;
  width: 30%;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 767px) {
  #home .course__flex li {
    width: 100%;
  }
}
#home .course__flex li a {
  display: block;
  text-decoration: none;
}
#home .course__category {
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
}
#home .course__ttl {
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
  color: #006F98;
  margin-bottom: 10px;
}
#home .course__logo {
  margin-bottom: 10px;
}
#home .course__txt {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.8rem;
}
#home .course__count {
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 20px;
}
#home .course__count span {
  font-size: 1.3rem;
}
#home .course__blue {
  background-color: #F4FCFF;
  padding: 25px 0;
}
#home .course__blue h4 {
  font-weight: bold;
  font-size: 0.9rem;
  color: #006F98;
  position: relative;
  margin-bottom: 10px;
  text-align: center;
}
#home .course__blue h4::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 28px;
  transform: translateY(-50%);
  width: 40px;
  height: 2px;
  background-color: #006F98;
}
#home .course__blue h4::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
  width: 40px;
  height: 2px;
  background-color: #006F98;
}
#home .course__blue p {
  padding: 0 25px;
}
#home .resolution {
  margin-top: 120px;
  margin-bottom: 120px;
  background-color: #F4FCFF;
  padding: 40px 0;
}
#home .resolution .ttl {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 60px;
}
@media screen and (max-width: 767px) {
  #home .resolution .ttl {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}
#home .resolution__flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}
@media screen and (max-width: 767px) {
  #home .resolution__flex {
    display: block;
  }
}
#home .resolution__flex img {
  width: 45%;
}
@media screen and (max-width: 767px) {
  #home .resolution__flex img {
    width: 100%;
    margin-bottom: 20px;
  }
}
#home .resolution__flex p {
  width: 45%;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  #home .resolution__flex p {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  #home .resolution .btn__flex {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  #home .resolution .btn__flex a {
    width: 100%;
    margin-bottom: 15px;
  }
}
#home .news {
  margin-top: 120px;
  margin-bottom: 120px;
}
#home .news .ttl {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 60px;
}
@media screen and (max-width: 767px) {
  #home .news .ttl {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}
#home .news ul {
  max-width: 900px;
  margin: auto;
  list-style-type: none;
  margin-bottom: 30px;
}
#home .news ul li {
  border-bottom: 1px solid #B4B4B4;
  font-weight: bold;
  padding: 25px 50px;
}
@media screen and (max-width: 767px) {
  #home .news ul li {
    padding: 10px;
  }
}
#home .news ul li a {
  text-decoration: none;
  font-size: 1.1rem;
}
#home .news ul li a .date {
  margin-right: 30px;
}
@media screen and (max-width: 767px) {
  #home .news ul li a .date {
    display: block;
  }
}
#home .news .bl_btn_sm {
  max-width: 365px;
  margin-top: 40px;
  padding: 10px;
}
@media screen and (max-width: 767px) {
  #home .news .bl_btn_sm {
    font-size: 1rem;
  }
}
#home .cta {
  margin-top: 120px;
  margin-bottom: 120px;
  background-color: #F8F8F8;
  padding: 40px 0;
}
#home .cta .ttl {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  #home .cta .ttl {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}
#home .cta p {
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.3rem;
}
#home .cta a {
  display: block;
  margin: auto;
  width: 100%;
  max-width: 320px;
  padding: 15px 0 15px 30px;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #000000;
  border-radius: 10px;
}
#home .cta a.x-regiter {
  border: 3px solid #000;
  position: relative;
}
#home .cta a.x-regiter::before {
  position: absolute;
  content: "";
  top: 47%;
  left: 28px;
  width: 25px;
  height: 25px;
  background-image: url(/assets/frontend/images/register/icon_x.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateY(-50%);
}
#home .cta a.go-regiter {
  border: 3px solid #ea4335;
  position: relative;
}
#home .cta a.go-regiter::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 28px;
  width: 29px;
  height: 29px;
  background-image: url(/assets/frontend/images/register/icon_google.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateY(-50%);
}
#home .cta a.git-regiter {
  border: 3px solid #0e0c0d;
  position: relative;
}
#home .cta a.git-regiter::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 28px;
  width: 26px;
  height: 26px;
  background-image: url(/assets/frontend/images/register/icon_github.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateY(-50%);
}
#home .cta a.fb-regiter {
  border: 3px solid #1878F2;
  position: relative;
}
#home .cta a.fb-regiter::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 28px;
  width: 29px;
  height: 29px;
  background-image: url(/assets/frontend/images/register/icon_facebook.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateY(-50%);
}

.swiper--wrapper {
  /* wrapperのサイズを調整 */
  width: 100%;
  height: 300px;
}

.swiper-slide {
  /* スライドのサイズを調整、中身のテキスト配置調整、背景色 */
  color: #ffffff;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 300px;
  text-align: center;
}

.swiper-slide:nth-child(3n+1) {
  background-color: #de4439;
}

.swiper-slide:nth-child(3n+2) {
  background-color: #fcd500;
}

.swiper-slide:nth-child(3n+3) {
  background-color: #53c638;
}

#navigation {
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 40px -13px #bdbdbd;
}
#navigation .header {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#navigation .header .logo {
  width: 100%;
  max-width: 120px;
  padding-top: 12px;
  display: block;
}
#navigation .header .logo svg {
  width: 100%;
}
@media screen and (max-width: 767px) {
  #navigation .header .navi {
    display: none;
  }
}
#navigation .header .navi ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 690px;
  list-style-type: none;
}
#navigation .header .navi ul li {
  padding: 1.2rem;
}
#navigation .header .navi ul li a {
  text-decoration: none;
  font-size: 0.9rem;
  color: #1B224C;
}
#navigation .header .navi ul li a:hover {
  color: #03B9FC;
  font-weight: bold;
}
#navigation .header .login_register {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
}
#navigation .header .login_register .login_link a {
  text-decoration: none;
  font-size: 0.9rem;
  color: #1B224C;
  padding: 1.2rem 0;
  display: block;
}
#navigation .header .login_register .login_link a:hover {
  color: #03B9FC;
  font-weight: bold;
}
#navigation .header .login_register .register_link {
  margin-top: 15px;
}
#navigation .header .login_register .register_link a {
  max-width: 100%;
  font-size: 0.9rem;
}
#navigation .header .login_register .accout_btn {
  max-width: 100%;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
#navigation .header .login_register .dropdown-content {
  display: none;
  position: absolute;
  top: 55px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
#navigation .header .login_register .dropdown-content.show {
  display: block;
}
#navigation .header .login_register .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 0.9rem;
}
#navigation .header .login_register .dropdown-content a:hover {
  background-color: #f1f1f1;
}

.breadcrumb {
  width: 100%;
  margin: 75px 0 20px;
}
.breadcrumb ul {
  list-style-type: none;
  display: flex;
  padding: 0;
}
.breadcrumb ul li {
  color: #868E96;
  position: relative;
  margin-right: 60px;
}
.breadcrumb ul li a {
  font-size: 0.9rem;
  text-decoration: none;
  color: #0877C9;
}
.breadcrumb ul li::before {
  position: absolute;
  content: "";
  top: 36%;
  left: -35px;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border: 1px solid;
  border-color: #565656 #565656 transparent transparent;
  transform: rotate(45deg);
}
.breadcrumb ul li:nth-child(1)::before {
  display: none;
}

.c-input {
  display: block;
  align-items: center;
}
.c-input label {
  font-size: 14px;
  font-weight: bold;
}
.c-input__ttl {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}
.c-input .c-input__required {
  margin-left: 10px;
  font-size: 11px;
  background-color: #ff293e;
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
}
.c-input input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}
.c-input input:focus {
  outline: none;
  border-color: #3cd5ff;
}
.c-input input[type=submit] {
  background-color: #03B9FC;
  color: #fff;
  width: 100%;
  max-width: 173px;
  text-align: center;
  display: block;
  margin: auto;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 10px;
  border: 1px solid #03B9FC;
}
.c-input input[type=checkbox] {
  width: auto;
  margin-right: 10px;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding-left: 30px;
  vertical-align: super;
  position: relative;
}
.c-input input[type=checkbox]::before, .c-input input[type=checkbox]::after {
  content: "";
  display: block;
  position: absolute;
}
.c-input input[type=checkbox]::before {
  background-color: #fff;
  border-radius: 0%;
  border: 1px solid #666464;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  top: 50%;
  left: 5px;
}
.c-input input[type=checkbox]::after {
  border-bottom: 3px solid #0877C9;
  border-left: 3px solid #0877C9;
  opacity: 0;
  height: 6px;
  width: 11px;
  transform: rotate(-45deg);
  top: -7px;
  left: 10px;
}
.c-input input[type=checkbox]:checked::after {
  opacity: 1;
}
.c-input input[type=radio] {
  width: auto;
  margin-right: 10px;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  position: relative;
  vertical-align: sub;
  margin-right: 8px;
}
.c-input input[type=radio]::before, .c-input input[type=radio]::after {
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
.c-input input[type=radio]::before {
  background-color: #fff;
  border: 1px solid #c2c1c1;
  height: 20px;
  width: 20px;
  left: 0px;
}
.c-input input[type=radio]::after {
  background-color: #0877C9;
  opacity: 0;
  height: 14px;
  width: 14px;
  left: 4px;
}
.c-input input[type=radio]:checked::after {
  opacity: 1;
}
.c-input__checkbox_label {
  margin-right: 20px;
}
.c-input__checkbox__blk {
  display: flex;
  flex-wrap: wrap;
}
.c-input__checkbox__blk__item {
  width: 33%;
  margin-bottom: 5px;
}
.c-input__radio__blk {
  display: flex;
  flex-wrap: wrap;
}
.c-input__radio__blk .c-input__checkbox__blk__item {
  width: 33%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.c-input__radio_label {
  margin-right: 20px;
}
.c-input select {
  width: 100%;
  font-size: 14px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}
.c-input textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}
.c-input textarea:focus {
  outline: none;
  border-color: #3cd5ff;
}

.validation__msg {
  color: #ff293e;
  font-size: 12px;
  margin-top: 5px;
  background-color: #ffd9dd;
  padding: 5px 10px;
  width: 100%;
}

.problem {
  margin-top: 65px;
}
.problem .inner {
  max-width: 100%;
}
.problem .inner.answer {
  max-width: 1200px;
  margin: auto;
}
.problem .inner.answer .problem__flex {
  justify-content: center;
}
.problem .inner.answer .problem__flex .problem__flex__content {
  width: 80%;
  overflow-y: auto;
  box-shadow: none;
}
.problem .inner.answer .problem__flex .problem__flex__content__ttl {
  text-align: center;
}
.problem__flex {
  display: flex;
  justify-content: center;
}
.problem__flex__sidebar {
  width: 30%;
  height: 100%;
  margin-left: 1%;
  max-height: 95%;
  min-height: 40%;
  overflow-y: scroll;
}
.problem__flex__sidebar::-webkit-scrollbar {
  width: 11px;
}
.problem__flex__sidebar::-webkit-scrollbar-track {
  background-color: #fcfcfc;
}
.problem__flex__sidebar::-webkit-scrollbar-thumb {
  background-color: #c4edff;
  border-radius: 15px;
}
.problem__flex__sidebar__block {
  margin-bottom: 30px;
  padding: 30px 25px 0px;
  background-color: #F4FCFF;
  box-shadow: 0 1px 3px rgba(160, 166, 179, 0.3);
  height: 100vh;
  overflow-y: scroll;
}
.problem__flex__sidebar__block::-webkit-scrollbar {
  width: 11px;
}
.problem__flex__sidebar__block::-webkit-scrollbar-track {
  background-color: #fcfcfc;
}
.problem__flex__sidebar__block::-webkit-scrollbar-thumb {
  background-color: #c4edff;
  border-radius: 15px;
}
.problem__flex__sidebar__block .problem__sidebar__ttl {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #006F98;
}
.problem__flex__sidebar__block .problem__sidebar__description {
  font-size: 0.9rem;
  margin-bottom: 40px;
}
.problem__flex__sidebar__block__courselist {
  padding: 0 15px 0px 15px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.problem__flex__sidebar__block__courselist li {
  line-height: 2.2;
  position: relative;
  width: 47%;
  text-align: center;
  margin-bottom: 20px;
}
.problem__flex__sidebar__block__courselist li p {
  font-size: 0.8rem;
}
.problem__flex__sidebar__block__courselist li .course__logo {
  border-radius: 30px;
}
.problem__flex__sidebar__block__courselist li a {
  text-decoration: none;
}
.problem__flex__sidebar__link__list {
  list-style: none;
}
.problem__flex__sidebar__link__list li a {
  text-decoration: none;
}
.problem__flex__content {
  width: 61%;
  margin: 0 1%;
  padding: 1%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(160, 166, 179, 0.3);
  overflow-y: scroll;
  height: 100vh;
}
.problem__flex__content::-webkit-scrollbar {
  width: 10px;
}
.problem__flex__content::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}
.problem__flex__content::-webkit-scrollbar-thumb {
  background-color: #a7def5;
  border-radius: 15px;
}
.problem__flex__content h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}
.problem__flex__content h3 span {
  font-size: 1rem;
  margin-left: 20px;
}
.problem__flex__content__txt {
  margin-bottom: 40px;
}
.problem__flex__content__editor {
  position: relative;
  display: block;
  height: 500px;
  margin-bottom: 120px;
}
.problem__flex__content__editor #editor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}
.problem__flex__content__editor.complete {
  height: auto;
}
.problem__flex__content .answer__ttl {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 30px 0 5px;
  text-align: center;
}
.problem__flex__content .answer__body {
  background-color: #f3f3f3;
  padding: 35px 40px;
  font-size: 0.9rem;
  margin: 50px 0 60px;
  position: relative;
  box-shadow: 0 1px 3px rgba(160, 166, 179, 0.3);
}
.problem__flex__content .answer__body::before {
  position: absolute;
  content: "Q.";
  font-size: 4rem;
  color: #8d8d8d;
  font-weight: bold;
  top: 0px;
  transform: translate(-50%, -50%);
  left: 5%;
  z-index: 0;
}
.problem__flex__content .answer__body__txt {
  position: relative;
  z-index: 1;
}
.problem__flex__content .answer__blk {
  background-color: #fff7ff;
  box-shadow: 0 1px 3px rgba(160, 166, 179, 0.3);
  padding: 35px 40px;
  position: relative;
}
.problem__flex__content .answer__blk::before {
  position: absolute;
  content: "A.";
  font-size: 4rem;
  color: #ffbde7;
  font-weight: bold;
  top: 0px;
  left: 5%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.problem__flex__content .answer__blk .answer__txt {
  font-size: 0.9rem;
  margin-bottom: 5px;
}
.problem__flex__content .answer__blk .input {
  border: 1px solid #c8c8c8;
  margin: 20px 0;
}
.problem__flex__content .answer__submit {
  margin: 40px 0;
}
.problem__flex__content .answer__submit.btn_list {
  display: flex;
}
.problem__flex__content .answer__submit.btn_list .bl_btn_back_md {
  max-width: 240px;
  width: 100%;
  color: #fff;
  background-color: #414141;
  border: 1px solid #414141;
  padding: 9px 15px;
  text-align: center;
  display: initial;
  margin: auto;
  text-decoration: none;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}
.problem__flex__content .answer__submit.btn_list .bl_btn_back_md:hover {
  background-color: #fff;
  color: #414141;
  border: 1px solid #414141;
}
.problem__flex__content .answer__submit.btn_list .bl_btn_md {
  display: initial;
}
.problem__flex__content .answer__ttl_complete {
  margin: 30px 0 20px;
  text-align: center;
  display: block;
}
.problem__flex__content .answer__btnlist {
  display: flex;
  max-width: 650px;
  width: 100%;
  margin: auto;
}
.problem__flex__document {
  width: 30%;
  background-color: #f7f7f7;
  box-shadow: 0 1px 3px rgba(160, 166, 179, 0.3);
  padding: 7px;
  margin-right: 1%;
  height: 100vh;
}
.problem__flex__document h4 {
  font-size: 1.2rem;
}
.problem__flex__document #previewArea {
  width: 100%;
  min-height: 40%;
  max-height: 95%;
  border: 1px solid #e6e6e6;
  background-color: #fff;
}

#register {
  width: 100%;
}
#register .register__snslink {
  margin-bottom: 190px;
}
#register .register__snslink .ttl {
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
}
#register .register__snslink .subttl {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 30px;
}
#register .register__snslink a {
  width: 300px;
  display: block;
  position: relative;
  text-align: center;
  border: 1px solid #d0d0d0;
  padding: 10px 0;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  margin: 0 auto 25px;
  border-radius: 10px;
}
#register .register__snslink a.register__x {
  border: 3px solid #000;
  padding-left: 20px;
}
#register .register__snslink a.register__x::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 65px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url(../images/register/icon_x.svg) no-repeat;
  background-size: 100%;
}
#register .register__snslink a.register__gobtn {
  border: 3px solid #EC4233;
  padding-left: 20px;
}
#register .register__snslink a.register__gobtn::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 65px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url(../images/register/icon_google.svg) no-repeat;
  background-size: 100%;
}
#register .register__snslink a.register__fbbtn {
  border: 3px solid #1878F2;
  padding-left: 20px;
}
#register .register__snslink a.register__fbbtn::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url(../images/register/icon_facebook.svg) no-repeat;
  background-size: 100%;
}
#register .register__snslink a.register__git {
  border: 3px solid #000;
  padding-left: 20px;
}
#register .register__snslink a.register__git::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 65px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url(../images/register/icon_github.svg) no-repeat;
  background-size: 100%;
}

#course {
  width: 100%;
  margin-bottom: 120px;
}
#course .course .ttl {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 60px;
}
#course .course__flex {
  display: flex;
  justify-content: left;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 70px;
  gap: 0 5%;
}
@media screen and (max-width: 767px) {
  #course .course__flex {
    display: block;
  }
}
#course .course__flex li {
  padding: 25px 25px;
  margin-bottom: 60px;
  width: 30%;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 767px) {
  #course .course__flex li {
    width: 100%;
  }
}
#course .course__flex li a {
  display: block;
  text-decoration: none;
}
#course .course__category {
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
}
#course .course__ttl {
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
  color: #006F98;
  margin-bottom: 10px;
}
#course .course__ttlcategory {
  font-size: 1.6rem;
  color: #006F98;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
}
#course .course__ttlcategory::before, #course .course__ttlcategory::after {
  content: "";
  height: 1px;
  flex-grow: 1;
  background-color: #006F98;
}
#course .course__ttlcategory:before {
  margin-right: 1rem;
}
#course .course__ttlcategory:after {
  margin-left: 1rem;
}
#course .course__logo {
  margin-bottom: 10px;
  display: block;
  margin: 10px auto;
}
#course .course__txt {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.8rem;
  text-align: center;
}
#course .course__count {
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 20px;
}
#course .course__count span {
  font-size: 1.3rem;
}
#course .course__blue {
  background-color: #F4FCFF;
  padding: 25px 0;
  margin-bottom: 20px;
}
#course .course__blue h4 {
  font-weight: bold;
  font-size: 0.9rem;
  color: #006F98;
  position: relative;
  margin-bottom: 10px;
  text-align: center;
}
#course .course__blue h4::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 28px;
  transform: translateY(-50%);
  width: 40px;
  height: 2px;
  background-color: #006F98;
}
@media screen and (max-width: 767px) {
  #course .course__blue h4::before {
    width: 20px;
  }
}
#course .course__blue h4::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
  width: 40px;
  height: 2px;
  background-color: #006F98;
}
@media screen and (max-width: 767px) {
  #course .course__blue h4::after {
    width: 20px;
  }
}
#course .course__blue p {
  padding: 0 25px;
}
#course .course__list {
  width: 100%;
  max-width: 800px;
  margin: auto;
}
#course .course__list ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
}
#course .course__list ul li {
  padding: 25px 25px;
  margin-bottom: 30px;
  width: 100%;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
}
#course .course__list ul li .course__list__block {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#course .course__list ul li .logo_img {
  width: 25%;
}
#course .course__list ul li .course__list__detail {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
#course .course__list ul li .course__list__detail__ttl {
  width: 68%;
}
#course .course__list ul li .course__list__detail__ttl .course__list__category {
  font-weight: bold;
  font-size: 0.8rem;
}
#course .course__list ul li .course__list__detail__ttl .course__list__ttl {
  font-weight: bold;
  font-size: 1.1rem;
  color: #006F98;
  margin-bottom: 10px;
}
#course .course__list ul li .course__list__detail__ttl .course__list__txt {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.8rem;
}
#course .course__list ul li .course__list__detail__post .course__list__count {
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 20px;
}
#course .course__list ul li .course__list__detail__post .course__list__count span {
  font-size: 1.3rem;
}

#contact .contact_form {
  width: 100%;
  max-width: 750px;
  margin: 0 auto 120px;
}
#contact .contact_form__lead {
  margin: 30px 0;
  font-size: 16px;
}
#contact .contact_form .send_btn {
  width: 100%;
  max-width: 300px;
  padding: 20px 0;
  text-align: center;
  display: block;
  margin: auto;
  color: #fff;
  background-color: #03B9FC;
  border: 1px solid #03B9FC;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 20px;
  cursor: pointer;
  letter-spacing: 2px;
}
#contact .contact_form .send_btn:hover {
  background-color: #fff;
  color: #03B9FC;
  border: 1px solid #03B9FC;
}
#contact .contact_complete {
  margin-bottom: 120px;
}
#contact .contact_complete__blk {
  width: 100%;
  text-align: center;
}
#contact .contact_complete__blk h3 {
  font-size: 22px;
}
#contact .contact_complete__blk h4 {
  font-size: 18px;
}
#contact .contact_complete__blk p {
  font-size: 16px;
  margin-bottom: 40px;
}

#operator {
  width: 100%;
  max-width: 750px;
  margin: 0 auto 120px;
}
#operator dl {
  display: flex;
  padding: 35px 0 20px;
  border-bottom: 1px solid #e9e9e9;
}
#operator dl dt {
  width: 100%;
  max-width: 200px;
}
#operator dl dd {
  width: 100%;
  max-width: 700px;
}

#information ul {
  list-style-type: none;
  margin: auto;
  margin-bottom: 150px;
  max-width: 1000px;
  width: 100%;
}
#information ul li {
  border-bottom: 1px solid #D5D5D5;
  padding: 15px 30px;
}
@media screen and (max-width: 767px) {
  #information ul li {
    padding: 15px 0;
  }
}
#information ul li a {
  display: flex;
  text-decoration: none;
  font-size: 1rem;
}
@media screen and (max-width: 767px) {
  #information ul li a {
    display: block;
  }
}
#information ul li a .date__label .date {
  max-width: 120px;
  width: 100%;
  margin-right: 40px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  #information ul li a .date__label .date {
    display: block;
  }
}
#information ul li a .date__label .label {
  padding: 3px 10px;
  background-color: #03B9FC;
}
#information ul li a .ttl {
  font-weight: bold;
  font-size: 15px;
}

#news_detail {
  margin-bottom: 230px;
}
#news_detail .news_detail__flex {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  #news_detail .news_detail__flex {
    display: block;
  }
}
#news_detail .news_detail__content {
  width: 100%;
  max-width: 765px;
}
#news_detail .news_detail__content .date_cetegory {
  margin-bottom: 5px;
  font-size: 14px;
}
#news_detail .news_detail__content .date_cetegory span {
  margin-left: 30px;
  background-color: #1B224C;
  color: #fff;
  padding: 3px 10px;
}
#news_detail .news_detail__content .ttl {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
#news_detail .news_detail__content .news_detail__body {
  font-size: 14px;
  min-height: 200px;
}
#news_detail .news_detail__content .news_detail_nextprev {
  display: flex;
  margin-top: 100px;
  align-items: center;
}
#news_detail .news_detail__content .news_detail_nextprev .prev {
  position: relative;
  font-size: 1rem;
  text-decoration: none;
  margin: 11px 15px;
  width: 33.3%;
}
@media screen and (max-width: 767px) {
  #news_detail .news_detail__content .news_detail_nextprev .prev {
    font-size: 0.8rem;
    margin: 10px 5px;
  }
}
#news_detail .news_detail__content .news_detail_nextprev .prev::before {
  position: absolute;
  content: "";
  top: 8px;
  left: -25px;
  width: 8px;
  height: 11px;
  background: url(../images/information/icon_arrow_left.svg) no-repeat;
}
@media screen and (max-width: 767px) {
  #news_detail .news_detail__content .news_detail_nextprev .prev::before {
    top: 7px;
    left: -15px;
  }
}
#news_detail .news_detail__content .news_detail_nextprev .next {
  position: relative;
  font-size: 1rem;
  text-decoration: none;
  margin: 11px 15px;
  width: 33.3%;
  text-align: right;
}
@media screen and (max-width: 767px) {
  #news_detail .news_detail__content .news_detail_nextprev .next {
    font-size: 0.8rem;
    margin: 10px 5px;
  }
}
#news_detail .news_detail__content .news_detail_nextprev .next::before {
  position: absolute;
  content: "";
  top: 8px;
  right: -25px;
  width: 8px;
  height: 11px;
  background: url(../images/information/icon_arrow_right.svg) no-repeat;
}
@media screen and (max-width: 767px) {
  #news_detail .news_detail__content .news_detail_nextprev .next::before {
    top: 7px;
    right: -15px;
  }
}
#news_detail .news_detail__content .news_detail_nextprev .btn {
  font-size: 1rem;
  border: 1px solid #03B9FC;
  background-color: #03B9FC;
  color: #fff;
  padding: 10px 40px;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  width: 33.3%;
}
@media screen and (max-width: 767px) {
  #news_detail .news_detail__content .news_detail_nextprev .btn {
    padding: 5px 20px;
    font-size: 0.8rem;
  }
}
#news_detail .news_detail__content .news_detail_nextprev .btn:hover {
  background-color: #fff;
  color: #03B9FC;
}
#news_detail .news_detail__sidebar {
  width: 100%;
  max-width: 300px;
  margin-bottom: 30px;
  padding: 30px 25px 0px;
  background-color: #F4FCFF;
}
@media screen and (max-width: 767px) {
  #news_detail .news_detail__sidebar {
    width: 100%;
    max-width: 100%;
    margin-top: 120px;
  }
}
#news_detail .news_detail__sidebar__ttl {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #006F98;
}
#news_detail .news_detail__sidebar ul {
  padding: 0 15px 0px 15px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#news_detail .news_detail__sidebar ul li {
  line-height: 2.2;
  position: relative;
  width: 47%;
  text-align: center;
  margin-bottom: 20px;
}
#news_detail .news_detail__sidebar ul li a {
  text-decoration: none;
}
#news_detail .news_detail__sidebar ul li .course__logo {
  border-radius: 30px;
}

#mypage {
  width: 100%;
}
#mypage .mypage__questionnaire {
  margin: 0px auto 30px;
  border: 1px solid #9ce2fb;
  background: #d1f3ff;
  color: #000;
  padding: 15px;
  font-weight: bold;
  text-align: center;
}
#mypage .mypage__questionnaire .bl_btn_xs {
  max-width: 180px;
  margin-top: 10px;
}
#mypage .mypage__flex {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex {
    display: block;
  }
}
#mypage .mypage__flex__sidebar {
  width: 28%;
  height: 100%;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__sidebar {
    display: none;
  }
}
#mypage .mypage__flex__sidebar__block {
  margin-bottom: 30px;
  padding: 30px 25px 0px;
  background-color: #F4FCFF;
}
#mypage .mypage__flex__sidebar__block h3 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #006F98;
}
#mypage .mypage__flex__sidebar__block__courselist {
  padding: 0 15px 0px 15px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#mypage .mypage__flex__sidebar__block__courselist li {
  line-height: 2.2;
  position: relative;
  width: 47%;
  text-align: center;
  margin-bottom: 20px;
}
#mypage .mypage__flex__sidebar__block__courselist li p {
  font-size: 0.8rem;
}
#mypage .mypage__flex__sidebar__block__courselist li .course__logo {
  border-radius: 30px;
}
#mypage .mypage__flex__sidebar__block__courselist li a {
  text-decoration: none;
}
#mypage .mypage__flex__sidebar__link__list {
  list-style: none;
}
#mypage .mypage__flex__sidebar__link__list li a {
  text-decoration: none;
}
#mypage .mypage__flex__content {
  width: 65%;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content {
    width: 100%;
  }
}
#mypage .mypage__flex__content .ttl {
  font-size: 2rem;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content .ttl {
    text-align: center;
  }
}
#mypage .mypage__flex__content .mypage__states {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 90px;
  align-items: center;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content .mypage__states {
    display: block;
  }
}
#mypage .mypage__flex__content .mypage__states .profile__img {
  width: 20%;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content .mypage__states .profile__img {
    width: 100%;
  }
}
#mypage .mypage__flex__content .mypage__states .profile__img img {
  border-radius: 50%;
  object-fit: cover;
  width: 80px;
  height: 80px;
  display: block;
  margin: auto;
}
#mypage .mypage__flex__content .mypage__states .profile__situation {
  width: 75%;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content .mypage__states .profile__situation {
    width: 100%;
  }
}
#mypage .mypage__flex__content .mypage__states .profile__situation .progress {
  margin-top: 15px;
}
#mypage .mypage__flex__content .mypage__states .profile__situation .progress__account {
  font-weight: bold;
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content .mypage__states .profile__situation .progress__account {
    text-align: center;
    margin-left: 0;
    margin-bottom: 20px;
  }
}
#mypage .mypage__flex__content .mypage__states .profile__situation .progress__percent {
  display: flex;
  align-items: center;
  column-gap: 5%;
  justify-content: flex-start;
  width: 90%;
  margin: auto;
}
#mypage .mypage__flex__content .mypage__states .profile__situation .progress__percent p {
  position: relative;
  font-size: 0.9rem;
  font-weight: bold;
}
#mypage .mypage__flex__content .mypage__states .profile__situation .progress__percent p span {
  font-size: 3rem;
  vertical-align: sub;
  margin-left: 10px;
}
#mypage .mypage__flex__content .mypage__states .profile__situation .progress__percent p::before {
  position: absolute;
  content: "";
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #707070;
}
#mypage .mypage__flex__content__resent, #mypage .mypage__flex__content__history {
  margin-bottom: 100px;
}
#mypage .mypage__flex__content__resent .mypage_subttl, #mypage .mypage__flex__content__history .mypage_subttl {
  font-size: 1.3rem;
  margin-bottom: 20px;
  margin-top: 25px;
}
#mypage .mypage__flex__content__resent .mypage_subttl span, #mypage .mypage__flex__content__history .mypage_subttl span {
  display: block;
  float: right;
}
#mypage .mypage__flex__content__resent .mypage_subttl span a, #mypage .mypage__flex__content__history .mypage_subttl span a {
  font-size: 0.9rem;
  position: relative;
  padding-right: 20px;
  color: #0877C9;
}
#mypage .mypage__flex__content__resent .mypage_subttl span a:before, #mypage .mypage__flex__content__history .mypage_subttl span a:before {
  position: absolute;
  content: "";
  top: 37%;
  right: 0;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border: 1px solid;
  border-color: #0877C9 #0877C9 transparent transparent;
  transform: rotate(45deg);
}
#mypage .mypage__flex__content__resent .course_history__flex, #mypage .mypage__flex__content__history .course_history__flex {
  display: flex;
  justify-content: space-between;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content__resent .course_history__flex, #mypage .mypage__flex__content__history .course_history__flex {
    display: block;
  }
}
#mypage .mypage__flex__content__resent .course_history__flex li, #mypage .mypage__flex__content__history .course_history__flex li {
  padding: 25px 25px;
  margin-bottom: 30px;
  width: 100%;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
}
#mypage .mypage__flex__content__resent .course_history__flex li .course_history__block, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content__resent .course_history__flex li .course_history__block, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block {
    display: block;
  }
}
#mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .logo_img, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .logo_img {
  width: 20%;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .logo_img, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .logo_img {
    width: 100%;
  }
}
#mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail {
  width: 75%;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail {
    width: 100%;
    display: block;
  }
}
#mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__ttl, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__ttl {
  width: 70%;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__ttl, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__ttl {
    width: 100%;
  }
}
#mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__ttl .course__category, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__ttl .course__category {
  font-weight: bold;
  font-size: 0.8rem;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__ttl .course__category, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__ttl .course__category {
    text-align: center;
  }
}
#mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__ttl .course__ttl, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__ttl .course__ttl {
  font-weight: bold;
  font-size: 1.1rem;
  color: #006F98;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__ttl .course__ttl, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__ttl .course__ttl {
    text-align: center;
  }
}
#mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__ttl .course__txt, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__ttl .course__txt {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.8rem;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__ttl .course__txt, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__ttl .course__txt {
    text-align: center;
  }
}
#mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__post .course__count, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__post .course__count {
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__post .course__count, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__post .course__count {
    text-align: center;
  }
}
#mypage .mypage__flex__content__resent .course_history__flex li .course_history__block .course__detail__post .course__count span, #mypage .mypage__flex__content__history .course_history__flex li .course_history__block .course__detail__post .course__count span {
  font-size: 1.3rem;
}
#mypage .mypage__flex__content__history .mypage_subttl {
  font-size: 1.3rem;
  margin-bottom: 20px;
  display: block;
}
#mypage .mypage__flex__content__history .mypage_subttl span {
  display: block;
  float: right;
}
#mypage .mypage__flex__content__history .mypage_subttl span a {
  font-size: 0.9rem;
  position: relative;
  padding-right: 20px;
  color: #0877C9;
}
#mypage .mypage__flex__content__history .mypage_subttl span a:before {
  position: absolute;
  content: "";
  top: 37%;
  right: 0;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border: 1px solid;
  border-color: #0877C9 #0877C9 transparent transparent;
  transform: rotate(45deg);
}
#mypage .mypage__flex__content .profile_tbl {
  width: 100%;
  margin-bottom: 150px;
  margin-top: 60px;
}
#mypage .mypage__flex__content .profile_tbl__profile_img {
  display: block;
  margin: auto;
}
#mypage .mypage__flex__content .profile_tbl__profile_img img {
  display: block;
  margin: auto;
  width: 80px;
  height: 80px;
}
#mypage .mypage__flex__content .profile_tbl table {
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content .profile_tbl table {
    width: 100%;
  }
}
#mypage .mypage__flex__content .profile_tbl table th {
  width: 20%;
  padding: 25px 15px;
  font-size: 1rem;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content .profile_tbl table th {
    display: block;
    width: 100%;
    text-align: left;
    border-bottom: none;
  }
}
#mypage .mypage__flex__content .profile_tbl table td {
  font-size: 1rem;
  padding: 25px 15px;
}
@media screen and (max-width: 767px) {
  #mypage .mypage__flex__content .profile_tbl table td {
    display: block;
    width: 100%;
    text-align: left;
  }
}
#mypage .withdrawal {
  text-align: center;
}
#mypage .withdrawal P {
  font-size: 1.2rem;
}
#mypage .withdrawal a {
  margin-top: 20px;
}

#terms {
  width: 100%;
  max-width: 750px;
  margin: 0 auto 120px;
}
#terms h3 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}
#terms p {
  margin-bottom: 40px;
}
#terms ol {
  padding-left: 25px;
  margin: 0 auto 40px;
}
#terms ol li {
  font-size: 0.9rem;
  line-height: 2;
}

#privacy {
  width: 100%;
  max-width: 750px;
  margin: 0 auto 120px;
}
#privacy h2 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}
#privacy p {
  margin-bottom: 40px;
}
#privacy ol {
  padding-left: 25px;
  margin: 0 auto 40px;
}
#privacy ol li {
  font-size: 0.9rem;
  line-height: 2;
}

#price {
  width: 100%;
}

#login {
  width: 100%;
  margin-bottom: 150px;
}
#login .subttl {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 30px;
  text-decoration: underline;
}
#login a {
  width: 330px;
  display: block;
  position: relative;
  text-align: center;
  border: 1px solid #d0d0d0;
  padding: 10px 0;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  margin: 0 auto 25px;
  border-radius: 10px;
}
#login a.register__x {
  border: 3px solid #000;
  padding-left: 20px;
}
#login a.register__x::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 65px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url(../images/register/icon_x.svg) no-repeat;
  background-size: 100%;
}
#login a.register__gobtn {
  border: 3px solid #EC4233;
  padding-left: 20px;
}
#login a.register__gobtn::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 65px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url(../images/register/icon_google.svg) no-repeat;
  background-size: 100%;
}
#login a.register__fbbtn {
  border: 3px solid #1878F2;
  padding-left: 20px;
}
#login a.register__fbbtn::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url(../images/register/icon_facebook.svg) no-repeat;
  background-size: 100%;
}
#login a.register__git {
  border: 3px solid #000;
  padding-left: 20px;
}
#login a.register__git::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 65px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url(../images/register/icon_github.svg) no-repeat;
  background-size: 100%;
}

#faq {
  width: 100%;
}
#faq .faq__flex {
  display: flex;
  justify-content: space-between;
}
#faq .faq__flex__sidebar {
  width: 28%;
  background-color: #F4FCFF;
  padding: 30px 25px;
  height: 100%;
}
#faq .faq__flex__sidebar__block {
  margin-bottom: 30px;
  border-bottom: 1px solid #006F98;
  padding-bottom: 20px;
}
#faq .faq__flex__sidebar__block h3 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}
#faq .faq__flex__sidebar__block ul {
  padding: 0 15px 0px 40px;
  list-style-type: none;
}
#faq .faq__flex__sidebar__block ul li {
  font-size: 0.8rem;
  line-height: 2.2;
  position: relative;
}
#faq .faq__flex__sidebar__block ul li::before {
  position: absolute;
  content: "";
  top: 46%;
  left: -21px;
  width: 13px;
  height: 13px;
  transform: translateY(-50%);
  background-color: #006F98;
  border-radius: 50%;
}
#faq .faq__flex__sidebar__block ul li a {
  text-decoration: none;
}
#faq .faq__flex__content {
  width: 65%;
}
#faq .faq__flex__content dl {
  margin-bottom: 50px;
}
#faq .faq__flex__content dl dt {
  font-weight: bold;
  font-size: 1.2rem;
  padding-bottom: 5px;
  padding-left: 25px;
  margin-bottom: 10px;
  border-bottom: 1px solid #D5D5D5;
  position: relative;
  margin-top: -100px;
  padding-top: 100px;
}

#help {
  width: 100%;
  margin: 0 auto 120px;
}
#help .help__flex {
  display: flex;
  justify-content: space-between;
}
#help .help__flex__sidebar {
  width: 28%;
  background-color: #F4FCFF;
  padding: 30px 25px;
  height: 100%;
}
#help .help__flex__sidebar__block {
  margin-bottom: 30px;
  border-bottom: 1px solid #006F98;
  padding-bottom: 20px;
}
#help .help__flex__sidebar__block h3 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}
#help .help__flex__sidebar__block ul {
  padding: 0 15px 0px 40px;
  list-style-type: none;
}
#help .help__flex__sidebar__block ul li {
  font-size: 0.8rem;
  line-height: 2.2;
  position: relative;
}
#help .help__flex__sidebar__block ul li::before {
  position: absolute;
  content: "";
  top: 46%;
  left: -21px;
  width: 13px;
  height: 13px;
  transform: translateY(-50%);
  background-color: #006F98;
  border-radius: 50%;
}
#help .help__flex__sidebar__block ul li a {
  text-decoration: none;
}
#help .help__flex__content {
  width: 65%;
}
#help .help__flex__content dl {
  margin-bottom: 50px;
}
#help .help__flex__content dl dt {
  font-weight: bold;
  font-size: 1.2rem;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #D5D5D5;
}

#about {
  width: 100%;
  margin-bottom: 150px;
}
#about .inner {
  margin: 90px auto;
}
@media screen and (max-width: 767px) {
  #about .inner {
    margin: 30px auto;
  }
}
#about .about__flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 180px;
}
@media screen and (max-width: 767px) {
  #about .about__flex {
    display: block;
    margin-bottom: 50px;
  }
}
#about .about__flex .img {
  width: 47%;
}
@media screen and (max-width: 767px) {
  #about .about__flex .img {
    width: 100%;
    padding: 20px 0px;
  }
}
#about .about__flex .txt {
  width: 47%;
  padding: 0 20px;
}
@media screen and (max-width: 767px) {
  #about .about__flex .txt {
    width: 100%;
    padding: 20px 0px;
  }
}
#about .about__flex .txt h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #006F98;
  position: relative;
  counter-increment: mycounter;
}
@media screen and (max-width: 767px) {
  #about .about__flex .txt h2 {
    font-size: 1.6rem;
  }
}
#about .about__flex .txt h2::before {
  content: "POINT";
  width: 100%;
  font-size: 100px;
  position: absolute;
  top: -85px;
  left: 0;
  color: #eaf9ff;
  z-index: -1;
  font-family: "Roboto", sans-serif;
}
#about .about__flex .txt p {
  font-size: 16px;
}
#about .about__center {
  width: 70%;
  margin: 0 auto 130px;
}
@media screen and (max-width: 767px) {
  #about .about__center {
    width: 100%;
  }
}
#about .about__center .img {
  width: 100%;
  display: block;
  margin: 0 auto;
}
#about .about__center .txt {
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
}
#about .about__center .txt h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #006F98;
}
#about .about__center .txt p {
  font-size: 16px;
  font-weight: bold;
}

.error {
  text-align: center;
  margin-bottom: 100px;
}
.error__ttl {
  font-size: 55px;
}
.error__txt {
  font-size: 16px;
  margin-bottom: 50px;
}

#footer .sns_link {
  width: 100%;
  max-width: 210px;
  margin: 30px auto 30px;
}
#footer .sns_link ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#footer .sns_link ul li {
  padding: 0 2rem;
  list-style: none;
}
#footer .footer__line {
  border-top: 1px solid #d0d0d0;
}
#footer .footer__link {
  padding: 50px 30px 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  max-width: 1200px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  #footer .footer__link {
    display: block;
  }
}
#footer .footer__link .logo {
  width: 100%;
  max-width: 153px;
}
@media screen and (max-width: 767px) {
  #footer .footer__link .logo {
    margin: 0 auto 40px;
  }
}
#footer .footer__link .footer__navi {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  #footer .footer__link .footer__navi {
    display: block;
  }
}
#footer .footer__link .footer__navi ul li {
  list-style-type: none;
  line-height: 2.4;
}
#footer .footer__link .footer__navi ul li a {
  font-size: 0.9rem;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  #footer .footer__link .footer__navi ul:nth-child(1) {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  #footer .footer__link .footer_login_btn {
    margin-top: 30px;
  }
}
#footer .footer__link .footer_login_btn p {
  font-size: 0.8rem;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  #footer .footer__link .footer_login_btn p {
    text-align: center;
    font-size: 1rem;
  }
}
#footer .footer__link .footer_login_btn a {
  background-color: #03B9FC;
  color: #fff;
  width: 100%;
  max-width: 173px;
  height: 43px;
  text-align: center;
  line-height: 43px;
  display: block;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 10px;
  border: 1px solid #03B9FC;
}
@media screen and (max-width: 767px) {
  #footer .footer__link .footer_login_btn a {
    display: block;
    margin: auto;
  }
}
#footer .footer__link .footer_login_btn a:hover {
  background-color: #fff;
  color: #03B9FC;
}
#footer .copy {
  text-align: center;
  font-size: 0.7rem;
  padding: 30px 0 30px;
}

.questionnaire__blk {
  margin: 50px auto 120px;
  max-width: 600px;
}
.questionnaire__blk__ttl {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 50px;
}
.questionnaire__blk__lists {
  margin: 0 auto 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #eeeeee;
}

.questionnaire__blk__lists__form_txt {
  margin: 7px 0;
  font-size: 14px;
}