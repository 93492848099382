#privacy{
    width: 100%;
    max-width: 750px;
    margin: 0 auto 120px;
    h2{
        font-size: 2em;
        font-weight: bold;
        margin-bottom: 20px;
    }
    p{
        margin-bottom: 40px;
    }
    ol{
        padding-left: 25px;
        margin: 0 auto 40px;
        li{
            font-size: .9rem;
            line-height: 2;
        }
    }
}
